var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":""}},[_c('span',{staticStyle:{"font-size":"22px","font-family":"poppinssemibold"}},[_vm._v("Home Carousel")])])],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","py-2":""}},_vm._l((_vm.navItems),function(item,i){return _c('v-flex',{key:i,attrs:{"px-2":""}},[_c('router-link',{attrs:{"to":'/homeSlider?content=' + item.name}},[_c('v-btn',{style:({
                border:
                  _vm.$route.query.content == item.name
                    ? '1px solid #ffe200'
                    : !_vm.$route.query.content &&
                      item.name == 'Upload Image'
                    ? '1px solid #ffe200'
                    : 'none',
              }),attrs:{"block":"","text":"","ripple":false}},[_c('span',{staticStyle:{"font-family":"poppinssemibold","font-size":"14px","color":"#343434","text-transform":"none"}},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.$route.query.content == 'Upload Image'|| !_vm.$route.query.content)?_c('HomeImage'):_vm._e(),(_vm.$route.query.content == 'Upload Text')?_c('HomeText'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }